

























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import searchTool from '../other/SearchToolBar.vue'
import svgCompany from '../../../components/svg_saves/main/CompanyGreen.vue'
import svgCross from '../../../components/svg_saves/main/Cross.vue'
import _ from 'lodash'
const returnDatas: any = {}
@Component({
  components: {
    svgCompany,
    svgCross,
    searchTool,
  },
  data() {
    return {
      svgCross,
    }
  },
})
export default class GlobalListRanter extends Vue {
  @Prop({ required: true }) public rantersData!: any // 租户全部人员
  @Prop({ required: true }) public ranterNavShow!: boolean // 是否显示flag
  @Prop() public closeAccess!: boolean // 添加租户flag
  public theWindowH = document.body.clientHeight
  public allRanter: any = this.rantersData
  public ranterFilter: any[] = []
  public ranterTapIndex: any = +(sessionStorage.getItem('ranterId') || -10)// 只在本页面与解散租户使用
  public childrenLength : any = _.values(this.rantersData).length
  public ranterID : number = +(sessionStorage.getItem('ranterId') || -10)
  @Watch('rantersData') public ranterDAtaFresh() {
    this.allRanter = this.rantersData
    this.childrenLength =  _.values(this.rantersData).length
    this.ranterTapIndex = +(sessionStorage.getItem('ranterId') || -10)
  }
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('rantersData') public getRanter() {
    this.allRanter = this.rantersData
  }
  public searchAction(returnData) {
    if (returnData.plusFlag) {
      this.addRanter()
    }
    const searchValues = returnData.searchValue // 输入框搜索的值
    if (searchValues.length > 0) {
      const filterData : any[] = _.compact(_.values(this.allRanter)
        .map((item: any) => {
          if (item.name.indexOf(searchValues) > -1) {
            return item
          }
          return null
        }))
      if (filterData.length > 0) {
          this.ranterFilter = filterData
      } else {
          this.ranterFilter = [{name : ''}]
      }
    } else {
      this.ranterFilter = []
    }
  }
  public navChanger(index, ranterID, name , ranter) {
    if (this.ranterTapIndex.toString() !== index.toString()) {
      // 未做切换时不重复请求
      this.ranterID = ranterID
      this.ranterTapIndex = index
      sessionStorage.setItem('ranterNameChose', name)
      returnDatas.index = index // 切换租户
      returnDatas.ranterID = ranterID // 切换租户
      returnDatas.ranterNavShow = '' // 显示List
      returnDatas.addRanter = ''
      returnDatas.ranterName = name // 租户名称
      returnDatas.ranterData = ranter // 租户data
      this.$emit('navChanger', returnDatas)
    }
  }

  public addRanter() {
    this.ranterFilter = []
    returnDatas.index = -1
    returnDatas.ranterNavShow = ''
    returnDatas.addRanter = 'show' // 添加租户
    returnDatas.ranterName = sessionStorage.getItem('ranterNameChose') // 租户名称
    this.$emit('navChanger', returnDatas)
  }
  public autoClose() {
    this.ranterFilter = []
    if (this.closeAccess) {
      returnDatas.index = -1
      returnDatas.ranterNavShow = 'off' // 关闭List
      returnDatas.addRanter = 'off'
      returnDatas.ranterName = sessionStorage.getItem('ranterNameChose') // 租户名称
      this.$emit('navChanger', returnDatas)
    }
  }
  private created() {
    document.addEventListener('click', (e) => {
      if (this.ranterNavShow && e.clientX > 330) {
        this.autoClose()
      }
    })
  }
}
